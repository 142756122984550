<template>
    <div>
        <div class="ft20 cl-main ftw600">站长列表</div>

        <div class="mt20">
            <div class="bg-w">
                <div class="pd30">
                    <div>
                        <a-button type="primary" icon="plus" @click="addTypeAct()">添加站长</a-button>
                    </div>
                    <div class="form-search-box ">
                        <a-form layout="inline">
                            <a-form-item label="站长名称">
                                <a-input v-model="search.username" placeholder="请输入站长名称"></a-input>
                            </a-form-item>
                            <a-form-item label="账号">
                                <a-input v-model="search.mobile" placeholder="请输入登录账号"></a-input>
                            </a-form-item>
                            <a-form-item>
                                <a-button @click="searchAct" type="primary">查询</a-button>
                                <a-button @click="cancelAct" class="ml10">取消</a-button>
                            </a-form-item>
                        </a-form>
                    </div>
                    <div class="">
                        <div class="wxb-table-gray">
                            <a-table rowKey="account_id" :columns="columns" :pagination="pagination" @change="handleTableChange"
                                     :data-source="datas" :loading="loading">

                                <div slot="face" slot-scope="face,record" class="flex">
                                  <img v-if="record.face!=null" :src="record.face" class="member-index-face" />
                                  <img v-else src="../../../assets/image/woyaoyou_head_defult@2x.png" class="member-index-face" />
                                  <div class="ml5" style="text-align: left;">
                                    <div>{{record.username}} </div>
                                    <div class="mt2">{{record.mobile}}</div>
                                  </div>
                                </div>
                                <div slot="status" slot-scope="status" class="flex alcenter left">
                                    <a-tag v-if="status == 1" color="green">
                                        正常
                                    </a-tag>
                                    <a-tag v-if="status == 0" color="red">
                                        冻结
                                    </a-tag>
                                </div>
                                <div slot="sex" slot-scope="sex" class="flex alcenter left">
                                    <a-tag v-if="sex == 1" color="green">
                                        女
                                    </a-tag>
                                    <a-tag v-if="sex == 2" color="cyan">
                                        男
                                    </a-tag>
                                    <a-tag v-if="sex == 0" color="red">
                                        未知
                                    </a-tag>
                                </div>
                                <div slot="bind_site" slot-scope="bind_site">
                                  <div  v-for="(val,index) in bind_site" color="purple" :key="index"  style="margin-bottom: 2px; float: left">
                                    <a-tag color="purple" >{{val}}</a-tag>
                                  </div>
                                </div>

                                <template slot="action" slot-scope="record,index">
                                    <div class="flex center">
                                        <a-dropdown placement="bottomRight">
											<span class="more-act">
												<i class="iconfont iconmore_gray"></i>
											</span>
                                            <a-menu slot="overlay">
                                                <a-menu-item>
                                                    <a class="menu-act" href="javascript:;" @click="editTypeAct(record)">
                                                        <i class="iconfont ft14 iconedit"></i>
                                                        <span class="ml10">编辑</span>
                                                    </a>
                                                </a-menu-item>
                                                <a-menu-item>
                                                    <a class="menu-act" href="javascript:;" @click="delTypeAct(record)">
                                                        <i class="iconfont ft14 icondelete"></i>
                                                        <span class="ml10">删除</span>
                                                    </a>
                                                </a-menu-item>
                                            </a-menu>
                                        </a-dropdown>
                                    </div>
                                </template>
                            </a-table>
                        </div>
                    </div>

                    <div v-if="editTypeVisible">
                        <edit-type :visible="editTypeVisible" :account_id="account_id" @cancel="cancelEditType" @ok="okEditType"></edit-type>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import {listMixin} from '@/common/mixin/list';
import editType from './components/masterEdit.vue';
export default{
    mixins: [listMixin],
    components:{
        editType,
    },
    data(){
        return{
            loading: false,
            editTypeVisible:false,
            pagination: {
                current: 1,
                pageSize: 10, //每页中显示10条数据
                total: 0,
                showTotal:total=>`共${total} 条数据`
            },
            account_id:0,
            search:{
                mobile:'',
                username:'',
            },
            columns: [
                {title: '编号', width: 120,dataIndex: 'number',align: 'left'},
                {title: '站长', width: 200,dataIndex: 'face',align: 'left',scopedSlots: {customRender: 'face'}},
                {title: '年龄',width: 120,dataIndex: 'age',align: 'left',ellipsis: true},
                {title: '性别',width: 120,dataIndex: 'sex',align: 'left',ellipsis: true,scopedSlots: {customRender: 'sex'}},
                {title: '状态',width: 120,dataIndex: 'status',align: 'left',ellipsis: true,scopedSlots: {customRender: 'status'}},
                {title: '站点',dataIndex: 'bind_site',align: 'left',ellipsis: true,scopedSlots: {customRender: 'bind_site'}},
                {title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}}
            ],
            datas: [],
        }
    },
    methods:{
        getLists(){
            if(this.loading===true) return;
            this.loading=true;
            this.$http.api('platform/site/getAccountLists',{
                limit:this.pagination.pageSize,
                page:this.pagination.current,
                username:this.search.username,
                mobile:this.search.mobile,
            }).then(res=>{
                this.pagination.total=res.total;
                this.datas=res.list;
                this.loading=false;
            }).catch(res=>{
                this.loading=false;
            })
        },

        delTypeAct(record){
            this.$confirm({
                title:'数据删除后不可恢复，确认删除此数据吗？',
                okText:"确定",
                okType:"danger",
                cancelText:"取消",
                onOk : ()=>{
                    return new Promise((resolve,reject)=>{
                        this.$http.api('platform/site/delAccount',{
                            account_id:record.account_id,
                        }).then(res=>{
                            this.$message.success('删除成功',1,()=>{
                                this.getLists();
                            });
                        }).catch(res=>{
                            console.log(res);
                        }).finally(()=>{
                            resolve();
                        })
                    })
                }
            })
        },

        addTypeAct(){
            this.account_id=0;
            this.editTypeVisible=true;
        },
        editTypeAct(record){
            this.account_id=record.account_id;
            this.editTypeVisible=true;
        },
        cancelEditType(){
            this.editTypeVisible=false;
        },
        okEditType(){
            this.editTypeVisible=false;
            this.getLists();
        },
        handleTableChange(pagination, filters, sorter) {
            this.pagination.current = pagination.current;
            this.getLists();
        },
    }
}
</script>

<style>
.member-index-face {
  width: 40px;
  height: 40px;
  border-radius: 44px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
  border: 2px solid #FFFFFF;
}
</style>
